/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import {Collapse, Image} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import CommonStore from '../../store/CommonStore';
import {ChevronBigBottom} from '../CustomIcons';
import visa from '../../assets/img/Visa.svg';
import mastercard from '../../assets/img/MasterCard.svg';
import isracard from '../../assets/img/isracard.svg';
import useScreenWidth from '../../hooks/useScreenWidth';
import api from '../../api/api';
import {TermType} from '../../types';
import ContactUs from '../ContactUs';
// import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {autorun} from 'mobx';
import {parsePhoneNumber} from 'libphonenumber-js';

const {Panel} = Collapse;

const LayoutFooter = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const {t} = useTranslation();

  //
  const screenWidth = useScreenWidth();

  //
  const [content, setContent] = useState<{
    terms: TermType | null;
    privacy: TermType | null;
    payments: TermType | null;
    returns: TermType | null;
    shipment: TermType | null;
  }>({
    terms: null,
    privacy: null,
    payments: null,
    returns: null,
    shipment: null,
  });

  //
  const [address, setAddress] = useState<string>();

  const onChange = (key: string | string[]) => {};

  useEffect(() => {
    (async () => {
      const resp = await api('api/terms');
      if (resp) {
        setContent(c => ({...c, terms: resp}));
      }
    })();
    (async () => {
      const resp = await api('api/privacy');
      if (resp) {
        setContent(c => ({...c, privacy: resp}));
      }
    })();
    (async () => {
      const resp = await api('api/payments');
      if (resp) {
        setContent(c => ({...c, payments: resp}));
      }
    })();
    (async () => {
      const resp = await api('api/returns');
      if (resp) {
        setContent(c => ({...c, returns: resp}));
      }
    })();
    (async () => {
      const resp = await api('api/shipment');
      if (resp) {
        setContent(c => ({...c, shipment: resp}));
      }
    })();
  }, []);

  //
  useEffect(() => {
    autorun(() => {
      (async () => {
        if (storeCommon.branch?.address?.google_address?.place_id) {
          // const gAddress = await geocodeByPlaceId(
          //   storeCommon.branch?.address.google_address?.place_id
          // );
          // console.log(gAddress);
          setAddress(
            storeCommon.branch.address.google_address.formatted_address,
          );
        }
      })();
    });
  }, []);

  return (
    <>
      <div className='ic-footer'>
        <div className='ic-wrapper'>
          <div className='ic-footer__top '>
            <div className='ic-footer__info'>
              <Link
                onClick={() => {
                  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }}
                to='/'
                className='ic-footer__info-logo'>
                {!!storeCommon.shop?.logo && (
                  <Image
                    className='ic-footer__info-logo__img'
                    src={
                      storeCommon.shop.logo
                        .url /*.replace('inclick.local', 'inclick.storeCommon.shop')*/
                    }
                    preview={false}
                    alt={storeCommon.shop.title}
                  />
                )}
                <div className='ic-footer__info-logo__title'>
                  {storeCommon.shop?.title}
                </div>
              </Link>

              {!!address && (
                <div className='ic-footer__info-address'>
                  {t('Address')}:&nbsp;&nbsp;
                  {address}
                </div>
              )}

              <div className='ic-footer__info-phones'>
                {t('Phone')}:
                <div className='ic-footer__info-phones-items'>
                  {storeCommon.branch?.phones.map(phone => (
                    <a
                      key={phone}
                      href={`tel:${
                        phone.startsWith('+') ? phone : `+${phone}`
                      }`}
                      target='_blank'
                      rel='noopener noreferrer'>
                      {parsePhoneNumber(
                        phone.startsWith('+') ? phone : `+${phone}`,
                      )?.formatNational()}
                    </a>
                  ))}
                </div>
              </div>
              <div className='ic-footer__info-email'>
                {t('Email')}:&nbsp;&nbsp;
                <a
                  href={`mailto:${storeCommon.branch?.email}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  dir='ltr'>
                  {storeCommon.branch?.email}
                </a>
              </div>
              <div className='ic-footer__info-socials'>
                {!!storeCommon.branch?.phones[0] && (
                  <a
                    href={`https://web.whatsapp.com/send?phone=${storeCommon.branch?.phones[0]}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ic-footer__info-socials__whatsapp'
                  />
                )}
                {!!storeCommon.shop?.socials?.facebook && (
                  <a
                    href={storeCommon.shop?.socials.facebook}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ic-footer__info-socials__facebook'
                  />
                )}
                {!!storeCommon.shop?.socials?.instagram && (
                  <a
                    href={storeCommon.shop?.socials.instagram}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ic-footer__info-socials__instagram'
                  />
                )}
                {!!storeCommon.shop?.socials?.twitter && (
                  <a
                    href={storeCommon.shop?.socials.twitter}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ic-footer__info-socials__twitter'
                  />
                )}
                {!!storeCommon.shop?.socials?.youtube && (
                  <a
                    href={storeCommon.shop?.socials.youtube}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ic-footer__info-socials__youtube'
                  />
                )}
                {!!storeCommon.branch?.phones[0] && (
                  <a
                    href={`tel:${storeCommon.branch?.phones[0]}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='ic-footer__info-socials__tel'
                  />
                )}
              </div>
            </div>
            <Collapse
              defaultActiveKey={screenWidth > 640 ? ['1', '2', '3'] : []}
              onChange={onChange}
              collapsible={screenWidth > 640 ? 'disabled' : 'header'}
              className='ic-footer__collapse'
              bordered={false}
              expandIcon={() => {
                return <ChevronBigBottom />;
              }}
              expandIconPosition='end'>
              <Panel
                header={t('Catalog')}
                key='1'
                className='ic-footer__catalog'
                showArrow={screenWidth > 640 ? false : true}
                style={{padding: 0}}>
                {storeCommon.shop?.categories.slice(0, 7).map(cat => (
                  <Link
                    key={cat._id}
                    className='ic-footer__catalog-item'
                    to={`/${cat.slug}`}>
                    {cat.title}
                  </Link>
                ))}
              </Panel>
              {!!Object.values(content).filter(c => !!c).length && (
                <Panel
                  header={t('Information')}
                  key='2'
                  className='ic-footer__information'
                  showArrow={screenWidth > 640 ? false : true}
                  style={{padding: 0}}>
                  {!!content.terms && (
                    <Link to='/terms' className='ic-footer__information-item'>
                      {t('Terms & Conditions')}
                    </Link>
                  )}
                  {!!content.privacy && (
                    <Link to='/privacy' className='ic-footer__information-item'>
                      {t('Privacy policy')}
                    </Link>
                  )}
                  {!!content.payments && (
                    <Link
                      to='/payments'
                      className='ic-footer__information-item'>
                      {t('Payments')}
                    </Link>
                  )}
                  {!!content.shipment && (
                    <Link
                      to='/shipment'
                      className='ic-footer__information-item'>
                      {t('Shipment')}
                    </Link>
                  )}
                  {/* <Link to="" className="ic-footer__information-item">
                {t("Size Chart")}
              </Link> */}
                  {!!content.returns && (
                    <Link to='/returns' className='ic-footer__information-item'>
                      {t('Returns & Warranty')}
                    </Link>
                  )}
                </Panel>
              )}
              <Panel
                header={t('About')}
                key='3'
                className='ic-footer__about'
                showArrow={screenWidth > 640 ? false : true}
                style={{padding: 0}}>
                {/* <Link to='' className='ic-footer__about-item'>
                  {t('About Us')}
                </Link> */}
                <ContactUs />
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
      <div className='ic-footer__bottom'>
        <div className='ic-wrapper'>
          <div className='ic-footer__bottom-copyright'>
            Copyright © All rights Reserved
          </div>
          <div
            className='ic-footer__bottom-center'
            style={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: '19px',
            }}>
            {t('Created with')}
            <a href='https://inclick.shop/' target='_blank' rel='noreferrer'>
              <div className='ic-footer__bottom-inclicklogo'></div>
            </a>
          </div>
          <div className='ic-footer__payment'>
            <img src={visa} style={{marginInlineEnd: 25}} />
            <img src={mastercard} style={{marginInlineEnd: 25}} />
            <img src={isracard} style={{width: 52}} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutFooter;
