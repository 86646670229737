import { Button, Modal, Select, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import CommonStore from '../../store/CommonStore';
import { CloseIcon, LocationIcon } from '../CustomIcons';
import { useTranslation } from 'react-i18next';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { Branch } from '../../types';

const MAX_DISTANCE = 10;

interface LocationBtnProps {
  mobile?: boolean;
}

interface Address {
  flat: string;
  building: string;
  city: string;
  country: string;
  street: string;
  location?: { lat: number; lng: number };
  google_address?: google.maps.GeocoderResult;
}

const AddressBtn = ({ mobile = true }: LocationBtnProps) => {
  //
  const storeCommon = useContext(CommonStore);
  //
  const { t, i18n } = useTranslation();
  //
  const [branchId, setBranchId] = useState(localStorage.getItem('branch'));

  const [googleAddress, setGoogleAddress] =
    useState<google.maps.GeocoderResult>();

  const [address, setAddress] = useState<Address | undefined>(
    localStorage.getItem('address')
      ? JSON.parse(localStorage.getItem('address') || '')
      : undefined
  );

  // const [open, setOpen] = useState(!address);
  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  //
  const [addressInput, setAddressInput] = useState(
    // address ? `${address.street} ${address.building}, ${address.city}` : ''
    address ? address.city : ''
  );

  //
  const changeBranch = () => {
    if (!branchId || !address) {
      return;
    }

    // if (!address.building) {
    //   // 'Please, write down Bulding Number after Street name'
    //   setShowError(true);
    //   return;
    // }

    if (address) {
      localStorage.setItem('address', JSON.stringify(address));
      var evt = document.createEvent('StorageEvent');
      evt.initStorageEvent(
        'storage',
        false,
        false,
        'address',
        'oldValue',
        'newValue',
        undefined,
        window.localStorage
      );
      window.dispatchEvent(evt);
    }
    if (storeCommon.branch?._id === branchId) {
      setOpen(false);
      return;
    }
    const branch = storeCommon.shop?.branches.find((b) => b._id === branchId);
    if (!branch) {
      return;
    }
    storeCommon.setBranch(branch);
    localStorage.setItem('branch', branch._id);
    storeCommon.clearCart();
    setOpen(false);
    var evt = document.createEvent('StorageEvent');
    evt.initStorageEvent(
      'storage',
      false,
      false,
      'branch',
      'oldValue',
      'newValue',
      undefined,
      window.localStorage
    );
    window.dispatchEvent(evt);
  };

  //
  useEffect(() => {
    if (googleAddress && googleAddress.address_components.length >= 2) {
      const address_data: Address = {
        flat: '',
        building: '',
        city: '',
        country: 'IL',
        street: '',
      };

      googleAddress.address_components.forEach((comp) => {
        if (comp.types.includes('country')) {
          address_data.country = comp.short_name;
        }
        if (comp.types.includes('locality')) {
          address_data.city = comp.long_name;
        }
        // if (comp.types.includes('route')) {
        //   address_data.street = comp.long_name;
        // }
        // if (comp.types.includes('street_number')) {
        //   address_data.building = comp.long_name;
        // }
      });

      address_data.location = googleAddress.geometry.location.toJSON();
      address_data.google_address = JSON.parse(JSON.stringify(googleAddress));

      setAddress(address_data);
      setShowError(false);

      // console.log('address_data', address_data);

      setAddressInput(
        // `${address_data.street} ${address_data.building}, ${address_data.city}`
        address_data.city
      );

      let distances: { branch: Branch; distance: number }[] = [];

      storeCommon.shop?.branches.forEach((branch) => {
        if (address_data.location) {
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              address_data.location,
              branch.address.location
            );
          distances.push({ branch, distance });
        }
      });

      distances = distances.sort((a, b) => a.distance - b.distance);

      if (distances[0] && distances[0].distance < MAX_DISTANCE * 1000) {
        setBranchId(distances[0].branch._id);
      } else {
        setBranchId(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAddress]);

  //
  useEffect(() => {
    // if (localStorage.getItem('need_address')) {
    //   setOpen(true);
    //   localStorage.removeItem('need_address');
    // }
  }, []);

  // console.log('NEW ADDRESS ==>> ', address);

  return (
    <>
      <Observer>
        {() => (
          <Button
            onClick={() => {
              setOpen((o) => !o);
            }}
            className={classNames('ic-header__location', {
              header: !mobile,
            })}
            type="link"
            icon={<LocationIcon />}
          >
            <span className="ic-header__location-text">
              {t(storeCommon.branch?.address.city || '')}
            </span>
          </Button>
        )}
      </Observer>

      <Modal
        className="ic-template ic-login-modal"
        closeIcon={<CloseIcon />}
        // closable={false}
        open={open}
        onCancel={() => {
          setOpen(false);
          // setSelectId(localStorage.getItem('branch'));
        }}
        footer={
          <Button
            block
            onClick={changeBranch}
            className="ic-header__location-modal-btn"
            type="primary"
          >
            {t('Confirm')}
          </Button>
        }
      >
        <div
          className="ic-header__location-modal ic-template"
          style={{ minHeight: 'auto', display: 'block' }}
        >
          <Typography.Title
            className="ic-header__location-modal-title"
            level={3}
          >
            {t('Please, select city you want to deliver orders')}
          </Typography.Title>
          <Observer>
            {() => (
              <div
                className="ic-header__location-modal-wrapper ic-checkout"
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlock: 20,
                }}
              >
                {/* <Select
                  className="ic-header__location-select".ic-template .ic-checkout .google-autocomplete-input__input input
                  style={{ width: '100%' }}
                  options={storeCommon.shop?.branches.map((b) => ({
                    value: b._id,
                    label: t(b.address.city),
                  }))}
                  value={selectId}
                  onChange={setSelectId}
                /> */}
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyAWGFcybOamRpU1qt0L9TfCLfkeBoMJPdA"
                  apiOptions={{
                    // language: i18n.language,
                    language: 'he',
                    region: 'il',
                    libraries: ['geometry', 'places'],
                  }}
                  selectProps={{
                    styles: {
                      control: (provided: any) => ({
                        ...provided,
                        border: '1px solid black',
                        borderRadius: '4px',
                      }),
                    },
                    value: null,
                    inputValue: addressInput,
                    onInputChange: (value: string, e: { action: string }) => {
                      if (e.action === 'input-change') {
                        setAddressInput(value);
                      }
                    },
                    onChange: async (value: any) => {
                      const resp = await geocodeByPlaceId(value.value.place_id);
                      setGoogleAddress(resp[0]);
                    },
                    placeholder: t('Start typing address here') + '...',
                    classNamePrefix: 'google-autocomplete-input',
                  }}
                  debounce={500}
                  minLengthAutocomplete={3}
                  autocompletionRequest={{
                    componentRestrictions: { country: 'il' },
                    types: ['locality'],
                  }}
                />
                {!!branchId &&
                  storeCommon.branch?._id !== branchId &&
                  !!storeCommon.cart.length && (
                    <div className="ic-header__location-modal-text">
                      {t(
                        'You have items in your shopping cart. When you change your address, your shopping cart will be emptied. Do you wish to continue?'
                      )}
                    </div>
                  )}

                {!branchId && (
                  <div className="ic-header__location-modal-text">
                    {t(
                      'Unfortunatly we have no possibility to deliver to this address'
                    )}
                  </div>
                )}

                {/* {showError && (
                  <div className="ic-header__location-modal-text">
                    {t('Please, write down Bulding Number after Street name')}
                  </div>
                )} */}
              </div>
            )}
          </Observer>
        </div>
      </Modal>
    </>
  );
};

export default AddressBtn;
