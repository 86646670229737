import {
  Avatar,
  Badge,
  Button,
  Col,
  Drawer,
  Dropdown,
  Image,
  Input,
  Menu,
  Modal,
  Popover,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import CommonStore from '../../store/CommonStore';

import { Observer } from 'mobx-react';
import CartDropdown from '../CartDropdown';
import UserLoginForm from '../UserLoginForm';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BurgerIcon, CloseIcon } from '../CustomIcons';
import { SearchOutlined } from '@ant-design/icons';
import Config from '../../Config';
import useScreenWidth from '../../hooks/useScreenWidth';
import AddressBtn from './AddressBtn';
// import LocationBtn from './LocationBtn';

const { Text } = Typography;
const { Search } = Input;

const LayoutHeader = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const { t, i18n } = useTranslation();

  //
  const screenWidth = useScreenWidth();

  //
  const history = useHistory();

  //
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  // const [langOpen, setLangOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('language') || 'he'
  );
  const [open, setOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);

  // const [cartVisible, setCartVisible] = useState(false);

  //
  const onLogout = () => {
    storeCommon.setUser(null);
    storeCommon.clearCart();
    localStorage.removeItem('token');
    localStorage.removeItem('cart');

    history.replace('/');
  };

  //
  // const menuItems: MenuProps['items'] = [
  //   {
  //     key: 'shop',
  //     label: <Link to={''}>{t('Shop')}</Link>,
  //   },
  //   {
  //     key: 'Categories',
  //     label: <Link to={'/categories'}>{t('Categories')}</Link>,
  //   },
  //   {
  //     key: 'about',
  //     label: <Link to={'/about'}>{t('About')}</Link>,
  //   },
  //   {
  //     key: 'Blog',
  //     label: <Link to={'/blog'}>{t('Blog')}</Link>,
  //   },
  // ];

  // const handleScroll = (ev: Event) => {
  //   // @ts-ignore
  //   if (window.scrollY > 100 && cartPopupRef.current?.state.popupVisible) {
  //     const button: HTMLButtonElement | null =
  //       document.querySelector('.ic-header__cart');
  //     if (button) {
  //       // button.click();
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  // }, []);

  return (
    <div className="ic-header">
      <div
        className="ic-wrapper"
        style={{
          height: 80,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Col
          xs={5}
          lg={10}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: 'transparent',
          }}
        >
          {!!storeCommon.shop?.categories.filter((cat) => cat.promotion)
            .length &&
            storeCommon.shop?.categories.filter((cat) => cat.promotion).length <
              7 && (
              <Menu
                className="ic-header__menu"
                theme="light"
                mode="horizontal"
                overflowedIndicator={<BurgerIcon />}
                style={{
                  background: 'transparent',
                  display: screenWidth > 991 ? 'flex' : 'none',
                }}
                // disabledOverflow
                // items={menuItems}
              >
                {storeCommon.shop?.categories
                  .filter((cat) => cat.promotion) /*.slice(0, 4)*/
                  .map((cat) => (
                    <Menu.Item key={cat._id} className="ic-header__menu-item">
                      <Link to={`/${cat.slug}`}>{cat.title}</Link>
                    </Menu.Item>
                  ))}
              </Menu>
            )}
          {!!storeCommon.shop?.categories.filter((cat) => cat.promotion)
            .length &&
            storeCommon.shop?.categories.filter((cat) => cat.promotion)
              .length >= 7 &&
            screenWidth > 640 && (
              <Search
                addonBefore={<SearchOutlined />}
                placeholder={t('Search')}
                allowClear
                // enterButton={t("Search")}
                enterButton={false}
                size="small"
                className="ic-searchBlock"
                onSearch={(filter) => {
                  history.push(`/filter("title":"${filter}")`);
                  setActiveModal(false);
                }}
              />
            )}
          <Button
            type="default"
            onClick={() => setOpen(true)}
            icon={<BurgerIcon />}
            style={{
              border: 'none',
              display: screenWidth > 640 ? 'none' : 'block',
            }}
            className="ic-header__burger-menu"
          />
          <Drawer
            width={'100%'}
            title={
              !!storeCommon.shop?.logo?.url && (
                <Image
                  preview={false}
                  height={50}
                  className="ic-header__logo-img"
                  src={
                    storeCommon.shop.logo.url /*.replace(
              'inclick.local',
              'inclick.shop',
            )*/
                  }
                  alt={storeCommon.shop.title}
                />
              )
            }
            closeIcon={<CloseIcon />}
            placement={currentLanguage === 'he' ? 'right' : 'left'}
            className="ic-header__menu-mobile ic-template"
            onClose={() => setOpen(false)}
            open={open}
          >
            <div className="ic-header__menu-mobile__header">
              <Badge
                className="ic-header__badge badge_wishlist"
                count={storeCommon.wishlist.length}
              >
                <Link
                  style={{
                    pointerEvents:
                      storeCommon.wishlist.length > 0 ? 'all' : 'none',
                  }}
                  to={'/wishlist'}
                  onClick={() => setOpen(false)}
                >
                  <Button
                    type="text"
                    className={classNames('ic-header__wishlist', {
                      active: storeCommon.wishlist.length > 0,
                    })}
                  >
                    {t('Wishlist')}
                  </Button>
                </Link>
              </Badge>

              {storeCommon.shop?.settings.languages &&
                storeCommon.shop.settings.languages.length > 1 && (
                  <Popover
                    className="ic-header__lng"
                    // open={langOpen}
                    trigger="click"
                    placement="bottom"
                    content={
                      <>
                        {storeCommon.shop.settings.languages
                          .filter((lang) => lang !== currentLanguage)
                          .map((lang) => (
                            <div key={lang}>
                              <Button
                                type="link"
                                onClick={() => {
                                  // setLangOpen(false);
                                  i18n.changeLanguage(lang);
                                  setCurrentLanguage(lang);

                                  var evt =
                                    document.createEvent('StorageEvent');
                                  evt.initStorageEvent(
                                    'storage',
                                    false,
                                    false,
                                    'language',
                                    'oldValue',
                                    'newValue',
                                    undefined,
                                    window.localStorage
                                  );
                                  window.dispatchEvent(evt);
                                }}
                                icon={
                                  <Image
                                    src={Config.languages[lang].icon}
                                    style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                      border: '1px solid #ddd',
                                    }}
                                    preview={false}
                                  />
                                }
                              />
                            </div>
                          ))}
                      </>
                    }
                  >
                    <Button
                      type="link"
                      // onClick={() => setLangOpen(s => !s)}
                      icon={
                        <Image
                          src={Config.languages[currentLanguage].icon}
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            objectFit: 'cover',
                            border: '1px solid #ddd',
                          }}
                          preview={false}
                        />
                      }
                      style={{
                        display: screenWidth > 991 ? 'none' : 'block',
                        marginInline: 24,
                      }}
                    />
                  </Popover>
                )}
              <Observer>
                {() => (
                  <>
                    {!!storeCommon.user && (
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item>
                              <Link
                                onClick={() => {
                                  setOpen(false);
                                }}
                                to="/account"
                              >
                                {t('Account')}
                              </Link>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item onClick={onLogout}>
                              {t('Logout')}
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                        placement="bottomLeft"
                        arrow
                      >
                        <div className="ic-header__user">
                          <Avatar
                            src={storeCommon.user.avatar}
                            className="ic-header__user-avatar"
                          >
                            {storeCommon.user.first_name?.[0] || '?'}
                            {storeCommon.user.last_name?.[0] || ''}
                          </Avatar>
                          {/* <span className='ic-header__user-name'>
                        {storeCommon.user.first_name}
                      </span> */}
                        </div>
                      </Dropdown>
                    )}
                    {!storeCommon.user && (
                      <Button
                        type="link"
                        className="ic-header__user-login"
                        onClick={() => setLoginModalVisible(true)}
                      >
                        {t('Login')}
                      </Button>
                    )}
                  </>
                )}
              </Observer>
            </div>
            {/* <div className='ic-header__menu-mobile__list'> */}
            <Row className="ic-header__menu-mobile-wrapper" gutter={[8, 24]}>
              {storeCommon.shop?.categories
                .filter((e) => e.menu_icon && e.menu_icon.url)
                .map((cat) => (
                  <Col
                    className="ic-header__menu-mobile-item"
                    span={8}
                    key={cat._id}
                  >
                    <Link
                      to={`/${cat.slug}`}
                      onClick={() => setOpen(false)}
                      key={cat._id}
                      // className='ic-header__menu-mobile__list-item'
                      className="ic-header__menu-mobile-item-link"
                    >
                      {cat.menu_icon?.url ? (
                        <img
                          alt=""
                          src={cat.menu_icon?.url}
                          className="ic-header__menu-mobile-item-img"
                        />
                      ) : (
                        <Skeleton.Image
                          className="ic-header__menu-mobile-item-img"
                          active={true}
                        />
                      )}
                      <span className="ic-header__menu-mobile-item-text">
                        {cat.title}
                      </span>
                    </Link>
                  </Col>
                ))}
            </Row>
            {/* </div> */}
          </Drawer>

          {screenWidth <= 640 &&
            storeCommon.shop?.branches &&
            // storeCommon.shop.branches.length > 1
            [
              ...storeCommon.shop.branches.map((b) => b.shipment),
              ...storeCommon.shop.shipment_methods,
            ].filter(
              (shipment) =>
                !!shipment?.address_required && shipment?._id !== 'HFD'
            ).length > 0 && (
              //
              <AddressBtn mobile={false} />
            )}
        </Col>
        {/* <div className='ic-header__logo'> */}
        <Col
          lg={4}
          xs={10}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Link
            className="ic-header__logo-link"
            to="/"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {!!storeCommon.shop?.logo && storeCommon.shop?.logo_show && (
              <Image
                preview={false}
                height={50}
                className="ic-header__logo-img"
                src={
                  storeCommon.shop.logo.url /*.replace(
                  'inclick.local',
                  'inclick.shop',
                )*/
                }
                alt={storeCommon.shop.title}
              />
            )}
            {storeCommon.shop?.title_show === true && (
              <Text
                className={classNames('ic-header__logo-title', {
                  image:
                    !!storeCommon.shop?.logo && storeCommon.shop?.logo_show,
                })}
              >
                {storeCommon.shop?.title}
              </Text>
            )}
          </Link>
        </Col>
        {screenWidth <= 640 && (
          <Button
            type="link"
            className="ic-header__search"
            onClick={() => setActiveModal(true)}
            // onClick={() =>
            //   setActiveKey(key => (key ? undefined : 'search'))
            // }
          >
            {t('Search')}
          </Button>
        )}
        <Col xs={1} lg={10}>
          <Observer>
            {() => (
              <>
                <div className="ic-header__items">
                  {screenWidth >= 640 &&
                    storeCommon.shop?.branches &&
                    // storeCommon.shop.branches.length > 1
                    [
                      ...storeCommon.shop.branches.map((b) => b.shipment),
                      ...storeCommon.shop.shipment_methods,
                    ].filter(
                      (shipment) =>
                        !!shipment?.address_required && shipment?._id !== 'HFD'
                    ).length > 0 && (
                      //

                      <AddressBtn />
                    )}
                  {screenWidth >= 640 &&
                    !!storeCommon.shop?.categories.filter(
                      (cat) => cat.promotion
                    ).length &&
                    storeCommon.shop?.categories.filter((cat) => cat.promotion)
                      .length < 7 && (
                      <Button
                        type="link"
                        className="ic-header__search-template"
                        onClick={() => setActiveModal(true)}
                      >
                        <span className="ic-header__search-template-icon" />
                      </Button>
                    )}
                  {storeCommon.shop?.settings.languages &&
                    storeCommon.shop.settings.languages.length > 1 && (
                      <Popover
                        className="ic-header__lng"
                        // open={langOpen}
                        trigger="click"
                        content={
                          <>
                            {storeCommon.shop.settings.languages
                              .filter((lang) => lang !== currentLanguage)
                              .map((lang) => (
                                <div key={lang}>
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      // setLangOpen(false);
                                      i18n.changeLanguage(lang);
                                      setCurrentLanguage(lang);

                                      var evt =
                                        document.createEvent('StorageEvent');
                                      evt.initStorageEvent(
                                        'storage',
                                        false,
                                        false,
                                        'language',
                                        'oldValue',
                                        'newValue',
                                        undefined,
                                        window.localStorage
                                      );
                                      window.dispatchEvent(evt);
                                    }}
                                    icon={
                                      <Image
                                        src={Config.languages[lang].icon}
                                        style={{
                                          width: 24,
                                          height: 24,
                                          borderRadius: '50%',
                                          objectFit: 'cover',
                                          border: '1px solid #ddd',
                                        }}
                                        preview={false}
                                      />
                                    }
                                  />
                                </div>
                              ))}
                          </>
                        }
                      >
                        <Button
                          type="link"
                          // onClick={() => setLangOpen(s => !s)}
                          icon={
                            <Image
                              src={Config.languages[currentLanguage].icon}
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '1px solid #ddd',
                              }}
                              preview={false}
                            />
                          }
                          style={{
                            display: screenWidth > 991 ? 'block' : 'none',
                          }}
                        />
                      </Popover>
                    )}
                  {storeCommon.wishlist.length >= 0 && (
                    <Badge
                      className="ic-header__badge badge_wishlist"
                      count={storeCommon.wishlist.length}
                    >
                      <Link to="/wishlist">
                        <Button
                          type="text"
                          className={classNames('ic-header__wishlist', {
                            active: storeCommon.wishlist.length > 0,
                          })}
                        >
                          {t('Wishlist')}
                        </Button>
                      </Link>
                    </Badge>
                  )}

                  {!!storeCommon.user && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item>
                            <Link to="/account">{t('Account')}</Link>
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item onClick={onLogout}>
                            {t('Logout')}
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                      placement="bottomLeft"
                      arrow
                    >
                      <div
                        className="ic-header__user"
                        style={{
                          display: screenWidth > 991 ? 'block' : 'none',
                        }}
                      >
                        <Avatar
                          src={storeCommon.user.avatar}
                          className="ic-header__user-avatar"
                        >
                          {storeCommon.user.first_name?.[0] || '?'}
                          {storeCommon.user.last_name?.[0] || ''}
                        </Avatar>
                        {/* <span className='ic-header__user-name'>
                        {storeCommon.user.first_name}
                      </span> */}
                      </div>
                    </Dropdown>
                  )}
                  {!storeCommon.user && (
                    <Button
                      style={{
                        display: screenWidth > 991 ? 'block' : 'none',
                      }}
                      type="link"
                      className="ic-header__user-login"
                      onClick={() => setLoginModalVisible(true)}
                    >
                      {t('Login')}
                    </Button>
                  )}

                  <>
                    <Badge
                      className="ic-header__badge badge_cart"
                      count={storeCommon.cart.length}
                    >
                      <Button
                        disabled={!storeCommon.cart.length}
                        onClick={() =>
                          storeCommon.cart.length > 0
                            ? setCartOpen((o) => !o)
                            : setCartOpen(false)
                        }
                        type="link"
                        className={classNames('ic-header__cart', {
                          active: storeCommon.cart.length > 0,
                        })}
                      >
                        {t('Cart')}
                      </Button>
                    </Badge>
                    <CartDropdown
                      cartOpen={cartOpen}
                      setCartOpen={setCartOpen}
                    />
                  </>
                </div>
              </>
            )}
          </Observer>
        </Col>
        {/* </div> */}
      </div>
      <Modal
        open={activeModal}
        onCancel={() => setActiveModal(false)}
        footer={null}
        width={'100%'}
        className={classNames('ic-template ic-searchModal', {
          desktopWrapper: screenWidth >= 640,
        })}
        bodyStyle={{ padding: '25px 0', maxWidth: 1530, margin: '0 auto' }}
        style={{ top: 0, maxWidth: '100%', borderRadius: 0 }}
      >
        <Search
          addonBefore={<SearchOutlined />}
          placeholder={t('Search')}
          allowClear
          enterButton={t('Search')}
          size="large"
          className={classNames('ic-searchBlock', {
            desktop: screenWidth >= 640,
          })}
          onSearch={(filter) => {
            history.push(`/filter("title":"${filter}")`);
            setActiveModal(false);
          }}
        />
      </Modal>
      {/* <Collapse
        activeKey={activeKey}
        defaultActiveKey={'search'}
        className='ic-search-collapse'
        destroyInactivePanel={false}
        bordered={false}>
        <Panel key='search' header='' forceRender>
          
          <Button
            ghost
            icon={<CloseOutlined />}
            onClick={() => setActiveKey('')}
            style={{marginInlineStart: 10, border: 'none'}}
          />
        </Panel>
      </Collapse> */}

      {!storeCommon.user && (
        <UserLoginForm
          visible={loginModalVisible}
          setVisible={(visible) => {
            setLoginModalVisible(visible);
          }}
          loginCallback={() => {
            setLoginModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default LayoutHeader;
